import { VNode } from 'vue';

import { DirectiveBinding } from 'vue/types/options';
import { getEventParams } from './UtmParamsConverter';
import { getGlobalThis } from '@/utils/commonUtils';
import typewriter from '../../../typewriter'
import { openConsentManager } from './SegmentConsentManager';
import { getStore } from '@/store';
import { Country } from '@/store/modules/metadataModule';
import Cookies from 'js-cookie';

const global = getGlobalThis() as any;

interface UtmModifiers {
  blockParent: boolean;
}

export class SegmentElement {
  el: HTMLElement;
  event: string;
  params?: any;
  parent?: SegmentElement;
  handler?: (event) => void;
  handlerEvent?: string;
  handlerElement?: HTMLElement;
  modifiers: UtmModifiers;

  constructor(el: HTMLElement) {
    this.el = el;
  }

  setEventName(event: string, binding: DirectiveBinding) {
    this.event = event;
    this.modifiers = binding.modifiers as any;
  }

  setParams(params: any) {
    this.params = Object.assign({}, this.params, params);
  }

  setLinkHandler(self: boolean = false) {
    this.handlerEvent = 'click';
    this.handler = () => {
      if (this.parent === undefined) this.parent = SegmentElement.findParent(this.el);

      SegmentElement.sendEvent(this.eventName(true), this.eventParams);
    };

    this.handlerElement = this.el;

    this.handlerElement.addEventListener(this.handlerEvent, this.handler);
  }

  setChangeHandler(self: boolean = false) {
    this.handlerEvent = 'change';
    this.handler = (event) => {
      if (this.parent === undefined) this.parent = SegmentElement.findParent(this.el);

      SegmentElement.sendEvent(this.eventName(true, event?.srcElement?.checked), this.eventParams);
    };

    this.handlerElement = this.el;
    if (!this.handlerElement.matches('input'))
      this.handlerElement = this.handlerElement.querySelector('input');

    this.handlerElement.addEventListener(this.handlerEvent, this.handler);
  }

  removeLinkHandler() {
    if (this.handler) {
      this.handlerElement.removeEventListener(this.handlerEvent, this.handler);
    }
  }

  eventName(withParent: boolean = false, value?: any) {
    const name = this.event;

    if (this.parent && withParent && !this.modifiers.blockParent)
      return `${this.parent.eventName()} - ${name}`;

    return name;
  }

  get eventParams() {
    return getEventParams(this?.params, this?.parent?.params);
  }

  static findParent(el: HTMLElement) {
    let parent = el.parentElement;
    while (parent) {
      const parentElement = SegmentElement.findElementByEl(parent);

      if (parentElement) return parentElement;
      else {
        parent = parent.parentElement;
      }
    }
    return null;
  }

  static findElementByEl(el: HTMLElement) {
    return this.Elements.find((e) => e.el === el);
  }

  static Elements: SegmentElement[] = [];

  static sendEvent(eventName: string, params: any = {}, debug = false) {
    params = {
      ...params,
      page_name: this.getPageName(),
      ...(eventName === SegmentTrackEvents.formSubmitted && { locale: this.getLocale() }),
    }
    if (debug) {
      if (process.env.NODE_ENV === 'development' || global.location.host.includes('dev') || global.location.host.includes('stg2019')) {
        console.log(`Segment: ${eventName}`, params);
      }
    }
    // if (params.nonInteraction && !window.scrollY) {
    //   setTimeout(() => {
    //     typewriter[eventName](params)
    //   }, 4500)
    // } else {
      typewriter[eventName](params)
    //}
  }

  static identify(userId, props) {
    if (global.analytics) {
      global.analytics.identify(userId, props)
    }
  }

  static getLocale() {
    const countryId = getStore().state.Meta.Country
    switch (countryId) {
      case Country.US:
        return 'USA'
      case Country.AUS:
        return 'Australia'
      case Country.WORLD:
        return 'World'
      case Country.UK:
        return 'United Kingdom'
    }
  }

  static getPageName(pathname?: string) {
    let initialPath = null;
    if (pathname)
      initialPath = pathname !== '/' ? pathname.split('/') : null;
    else
      initialPath = window.location.pathname !== '/' ? window.location.pathname.split('/') : null;

    let name: string;

    if (initialPath) {
      name = initialPath.reduce((mappedPath, pathItem) => {
        if (pathItem) mappedPath.push(pathItem.replace(/-/g, ' '))
        return mappedPath;
      }, []).join(': ')
    } else {
      name = 'homepage';
    }
    return name;
  }

  static getIsLanding() {
    return !document.referrer || !document.referrer.includes(window.location.host);
  }

  static getPrevPageName() {
    if (document.referrer)
      return SegmentElement.getPageName((new URL(document.referrer)).pathname);
    return "";
  }

  static async page(ApiProperties: any) {
    if (global.analytics) {
      const name = this.getPageName();

      const properties = {
        brand: 'Lindblad Expeditions',
        is_landing: this.getIsLanding(),
        ...(!!window.location.hash && { deep_link: window.location.hash.substring(1) }),
        ...ApiProperties,
      }

      //setTimeout(() => {
        global.analytics.page(name, properties)
      //}, 4000)
    }
  }

  static getButtonParams(button) {
    const href = button.getAttribute('href');
    return {
      text: button.innerText.trim(),
      url: href ? href : document.location.pathname,
    }
  }

  static addButtonTracking(event: Event) {
    const el = event.target as any;
    const href = el.getAttribute('href');

    const parentEl = el.closest('a[href^="tel:"]');
    const parentElHref = parentEl?.getAttribute('href');

    if (el.classList.contains('button')) {
      SegmentElement.sendEvent('buttonClicked', SegmentElement.getButtonParams(el));
    }
    if (el.closest('.helpButtonEnabled')) {
      SegmentElement.sendEvent('chatOpened');
    }
    if (el.parentNode) {
      if (el.parentNode.classList.contains('startButtonWrapper')) {
        setTimeout(() => {
          const hasError = document.querySelector('.embeddedServiceSidebar .error-message')?.getBoundingClientRect().height
          if (!hasError) {
            SegmentElement.sendEvent('chatRequested');
          }
        }, 2500);
      } 
      if (
        el.innerText === 'Start a New Chat' &&
        (el.classList.contains('embeddedServiceSidebarButton') ||
        el.parentNode.classList.contains('embeddedServiceSidebarButton'))
      ) {
        SegmentElement.sendEvent('chatRequested');
      }
    }
    if (el.classList.contains('consentManager')) {
      openConsentManager()
    }
    if ((href && href.startsWith('tel')) || (parentEl && parentElHref && parentElHref.startsWith('tel'))) {
      const module = el.closest('*[contentlinkid]') as HTMLElement;
      SegmentElement.sendEvent('phoneNumberClicked', {
        phone: href ? href.replace(/[^\d.-]/g, '') : parentElHref.replace(/[^\d.-]/g, ''),
        module_type_id: module.getAttribute('data-segmentid'),
        module_type_name: module.getAttribute('type'),
      }, true)
    }
  }

  static getTrackableLinks() {
    return [
    '.text-image a',
    '.content-text-generic a',
    '.carousel-rich__body a',
    '.has-trackable-links a',
    '.content-broken-grid a',
    '.plain-html-module a',
    '.article-text a',
    '.itinerary-intro__text--link',
    'a.cta:not(.not-trackable)',
    'a.cta--long-text-links:not(.cta)'
    ].join(', ')
  }

  static addLinkTracking(link) {
    link.addEventListener('click', () => SegmentElement.sendEvent('linkClicked', SegmentElement.getButtonParams(link)))
  }

  static RegisterElement(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    let element = SegmentElement.findElementByEl(el);
    if (!element) {
      element = new SegmentElement(el);
      SegmentElement.Elements.push(element);
    }
    if (binding.arg === 'params') element.setParams(binding.value); // v-segment:params="{key: value}"

    // if (['parent', 'click', 'link', 'change'].includes(binding.arg))
    //   element.setEventName(binding.value, binding);
    
    const self = binding?.modifiers?.self;
    
    switch (binding.arg) {
      case 'change':
        {
          element.setChangeHandler(self);
        }
        break;
      case 'click':
        {
          element.setEventName(binding.value, binding);
          if (binding.modifiers.linkParams) element.setParams(SegmentElement.getButtonParams(el));
          element.setLinkHandler(self);
        }
        break;
      case 'button':
        {
          element.setEventName('buttonClicked', binding);
          element.setParams(SegmentElement.getButtonParams(el));
          element.setLinkHandler(self);
        }
        break;
      case 'moduleClicked':
        {
          el.addEventListener('click', e => {
            const target = e.target as HTMLAnchorElement;
            if (!element.eventParams.text && target.tagName === 'A') {
              element.setParams({ text: target.innerText.trim() })
            }
            if (Object.values(vnode.context.$attrs).length) {
              vnode.context.$emit('moduleClicked', element.eventParams)
            } else {
              vnode.context.$parent.$emit('moduleClicked', element.eventParams)
            }
          })
        }
    }
  }

  static UnregiserElement(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    let element = SegmentElement.findElementByEl(el);
    if (element) {
      element.removeLinkHandler();
    }
  }
}

export interface TransactionId {
  transaction_id?: number
}

export enum SegmentTrackEvents {
  formSubmitted = 'formSubmitted',
  checkoutStarted = 'checkoutStarted',
  checkoutStepViewed = 'checkoutStepViewed',
  checkoutStepCompleted = 'checkoutStepCompleted',
  reservationRequestSubmitted = 'reservationRequestSubmitted',
  buttonClicked = 'buttonClicked',
}

export interface ModuleClickedOptionalProps {
  position?: number;
  text?: string;
  component_id?: string,
  component_name?: string,
}
export interface PagePerformanceCapturedProps {
  connection_type?: string,
  count_cls?: number,
  page_cls?: number,
  page_fcp?: number,
  page_fid?: number,
  page_lcp?: number,
  page_ttfb?: number,
}
